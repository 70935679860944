import React from "react";
import { Container } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
const policies = () => {
  return (
    <Container style={{ paddingTop: "20px" }}>
      <div className="logo-wrapper">
        <StaticImage
          className="mx-auto"
          src="../images/general/logo.svg"
          alt="logo"
          placeholder="blurred"
          layout="fixed"
          width={150}
          height={150}
        />
      </div>
      <div className="blog-details my-5 pb-5" style={{ direction: "rtl" }}>
        <h1 className="mb-4">سياسة الخصوصية</h1>
        <ul className="list-dots">
          <p>
            يوضح هذا البيان الملامح الرئيسية لسياسة الخصوصية الخاصة بمنصة بشورى
            والمعمول بها حالياً، أو المعدلة وذلك من أجل الإفصاح عن النهج الذي
            تتبعه شورى فيما يتعلق بالمعلومات الخاصة بك.
          </p>

          <li>
            استخدامك لشورى يعني قبولك لشروط وأحكام شورى وكذلك قبول بيان سياسة
            الخصوصية.
          </li>
          <li>
            توفر شورى مستوى عال من الخصوصية والأمان فيما يتعلق بالمعلومات
            الشخصية التي سيتم تقديمها عند التعامل مع شورى.
          </li>
          <li>
            المعلومات الشخصية تتضمن أي معلومات يتم من خلالها التعرف على شخصيتك
            بطريقة مباشرة أو غير مباشرة ومنها على سبيل المثال الاسم الكامل، رقم
            الهوية، رقم الجوال، بيانات الحساب البنكي والبطاقات الإئتمانية،
            البيانات التي يتم تعبئتها من خلال نماذج شورى، ..... إلخ (بما في ذلك
            ملفات تعريف الارتباط) أو أي تعاملات أخرى مع شورى.
          </li>
        </ul>
        <br />

        <h3 className="my-4">السياسة الخاصة بشورى:</h3>
        <ol>
          <li>
            الحفاظ على المعلومات من أهم أولوياتنا مع التأكيد على استخدامها فقط
            على النحو اللازم للاستفادة من التعاملات الخاصة مع شورى.
          </li>
          <li>
            الحفاظ على الإجراءات الأمنية، التقنية، الإدارية، الفنية الكفيلة لمنع
            أي وصول لأي من المعلومات الخاصة بك خارج شورى لضمان التعامل بما
            يتماشى مع سياسة الخصوصية هذه.
          </li>
          <li>
            اتخاذ اللازم لحماية بياناتك من الوصول غير المشروع أو غير المصرح به
            أو التغيير أو الإتلاف أو الإفصاح عنها.
          </li>
          <li>
            يقتصر الاطلاع على المعلومات الخاصة بك على منسوبي شورى المصرح لهم
            وبغرض تزويدك بالخدمة المطلوبة فقط.
          </li>
          <li>
            "استثناء" عند الاستعانة بأحد الجهات الخارجية لتقديم أي من الخدمات
            المساندة لشورى، يتم التأكيد عليها أولاً بضرورة الالتزام والتقيد
            بمعايير السرية المتبعة والمعتمدة لدى شورى بالإضافة إلى مراقبتنا
            الفعالة عليها للتأكد من امتثالها لهذه المعايير.
          </li>
          <li>
            الحد من جمع واستخدام معلومات العملاء إلى الحد الأدنى الذي تحتاجه
            شورى لتقديم خدماتها.
          </li>
          <li>
            التأكيد على عدم مشاركة أي من هذه المعلومات مع الغير خارج شورى إلا في
            الحالات التالية:
            <ul>
              <li>
                بناء على أمر قضائي من جهة قضائية بالمملكة العربية السعودية.
              </li>
              <li>
                إذا كانت المعلومات لتنفيذ طلب خاص بك عن طريق شورى مع موفر الخدمة
                أو المستفيد.
              </li>
              <li>بناء على اتفاق مسبق معكم.</li>
            </ul>
          </li>
          <li>
            تأمل منكم شورى المحافظة على بيانات الولوج إلى شورى وحمايتها وعدم
            إفشاءها إلى الغير لتفادي أي محاولة للولوج غير المصرح به، وتتحمل وحدك
            المسئولية عن النتائج المترتبة في حال عدم مراعاة ذلك.
          </li>
          <li>
            من المؤكد انه لا يتم مشاركة أو بيع أو نقل البيانات مع أي جهة غير
            تابعة لنا (باستثناء المعاونة أعلاه) بغرض التسويق أو لأي غرض آخر.
          </li>
        </ol>
        <br />

        <h3 className="my-4">ملفات تعريف الارتباط(الكوكيز).</h3>
        <ul className="list-dots">
          <li>
            تستخدم شورى ملفات تعريف الارتباط(المؤقتة/ الدائمة)، وهي ملفات نصية
            قصيرة تحتوي على معلومات يتم نقلها إلى القرص الصلب الخاص بك عن طريق
            الموقع الإلكتروني أو التطبيق الهاتفي أو أي جهاز آخر عند استخدامك
            لشورى، هذه الملفات تساعدنا في توفير المعلومات الخاصة بالأوقات التي
            قمت فيها بزيارتنا وأماكن تواجدك، وتحليل كيفية استخدامك للخدمة ومدى
            التفاعل مع المحتوى الذي تقدمه شورى بالإضافة إلى تمكينك من التنقل
            بكفاءة، وتذكر الإعدادات التي تفضلها، كما أن ملفات الارتباط هذه تعيد
            ارسال المعلومات في كل مرة تستخدم فيها شورى، علماً بأن هذه الملفات لا
            تكشف عن شخصيتك.
          </li>
          <li>
            إذا رغبت في استخدام شورى فسيتم إخطارك بقبول ملفات الارتباط أو رفضها،
            وفي حال رفض حفظ الملفات بالقرص الصلب قد لا تتمكن من استخدام جميع أو
            بعض الخدمات شورى.
          </li>
        </ul>
        <br />

        <h3 className="my-4">حدود المسئولية:</h3>
        <ul className="list-dots">
          <li>
            شورى لا تقدم أي تعهدات أو ضمانات من أي نوع، صراحة أو ضمناً، فيما
            يتعلق باكتمال، أو دقة، أو مصداقية، أو ملائمة، أو إتاحة شورى أو
            المعلومات، أو الخدمات التي تشملها شورى لأي غرض ويكون اعتمادك على أي
            من مثل هذه المعلومات تحت مسؤوليتك الخاصة بشكل نهائي وتام.
          </li>
          <li>
            شورى لن تتحمل تحت أية ظرف أية مسؤولية متعلقة بأية خسارة أو ضرر،
            ويشمل ذلك على سبيل المثال لا الحصر (الخسائر أو الأضرار المباشرة أو
            غير المباشرة أو التبعية) أو أي خسارة أو ضرر من أي نوع أو حجم قد تنجم
            عن فقدان أي من البيانات أو الأرباح الناتجة عن استخدام شورى.
          </li>
          <li>
            لا تتحمل شورى أية مسؤولية أو أي التزام متعلق بكون شورى غير متاحة
            بشكل مؤقت نتيجة لأمور فنية أو غيرها خارج عن السيطرة.
          </li>
          <li>
            إن اطّلاعكم على هذه السياسة أو استخدام شورى فإن ذلك يعني موافقتكم
            الضمنية على إبراء ذمة شورى ومدراءها وجميع الموظفين والوكلاء، وعدم
            تحميل أي منهم أية مسؤولية تنجم عن مطالبات أو تعويضات، وما يكتنف ذلك
            من أتعاب محاماة، تترتب على الإخلال بهذه السياسة، أو إساءة استخدامها
            أو استخدام شورى.
          </li>
        </ul>
        <br />

        <h3 className="my-4">التعديل:</h3>
        <p>
          لشورى الحق في تعديل سياسة الخصوصية بناء على إرادتها المنفردة وبدون
          إخطار مسبق، وسيتم نشر التعديلات من خلال شورى، استمرار استخدامك لشورى
          يعني قبولك بهذه التعديلات لذا يرجى مراجعة بيان سياسة الخصوصية من وقت
          لآخر حتى تكون على اطلاع دائم بأي تعديلات.
        </p>
        <br />

        <h3 className="my-4">القانون الواجب التطبيق:</h3>
        <p>
          تخضع سياسة الخصوصية هذه لأحكام القوانين والأنظمة السارية في المملكة
          العربية السعودية وتفسر بموجبها فقط.
        </p>
        <br />

        <h3 className="my-4">الاستفسارات:</h3>
        <ul className="list-dots">
          <li>
            في حال وجود أي استفسارات حول سياسة الخصوصية فيمكنكم التواصل معنا
            باستخدام أي من الوسائل املوضحة أدناه:
            <ul>
              <li>شركة شورى لتقنية المعلومات (الجهة المالكة)(.</li>
              <li>طريق أبو بكر الصديق حي الربيع مدينة الرياض</li>
              <li>
                <span>بريد الكتونى:</span>
                <span className="mx-2">info@shwra.sa</span>
              </li>
              <li>هاتف:920033635</li>
            </ul>
          </li>
        </ul>
      </div>
    </Container>
    
  );
};

export default policies;
